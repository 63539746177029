import React, { memo } from 'react'
import { Box, BoxProps, Divider, Typography, styled } from '@mui/material'

export interface ContentQuoteProps extends BoxProps {
  quote: string
  name: string
  location?: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  paddingLeft: '20px',
}))

const ContentQuote = ({
  quote,
  name,
  location,
  ...props
}: ContentQuoteProps) => {
  return (
    <StyledBox {...props}>
      <Typography variant="quote" component="div">
        &quot;{quote}&quot;
      </Typography>
      <Divider
        sx={{ width: '20px', my: '27px', backgroundColor: 'rgba(40,36,36,.2)' }}
      />
      <Typography variant="caption" sx={{ whiteSpace: 'normal' }}>
        <strong>- {name}</strong>
        {location && <>, {location}</>}
      </Typography>
    </StyledBox>
  )
}

export default memo(ContentQuote)
